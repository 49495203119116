
import * as aroraProviderRuntime$Bc0oLynV5wudWQR9QJ65KOB_45mIJLzZEh9HhQ9pmqLRI from 'D:/deploy/UploadHidden/20250320-P903.298/arora-front/satellite/build-rest-c69cbb15-5479-47f6-b22d-3ad5ebb99835/providers/aroraProvider.ts'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536
  },
  "presets": {},
  "provider": "aroraProvider",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['aroraProvider']: { provider: aroraProviderRuntime$Bc0oLynV5wudWQR9QJ65KOB_45mIJLzZEh9HhQ9pmqLRI, defaults: {} }
}
        